<template>
  <el-table
    :data="filteredReport25.finalResult"
    border
    size="small"
    v-loading="reportsStore.table_loading"
    show-summary
    class="h-100"
  >
    <el-table-column
      width="180"
      :label="`${$route.query.year || currentYear} yil`"
    >
      <el-table-column
        align="center"
        width="180"
        prop="staff_name"
        label="Ishchi xodim"
      />

      <el-table-column
        align="center"
        width="180"
        prop="position_name"
        label="Lavozimi"
      />

      <el-table-column
        align="center"
        width="110"
        v-for="(item, index) in 12"
        :key="item + 1"
        :label="months[item - 1]"
      >
        <template slot-scope="scope">{{
          (scope.row.calced_salary_by_month &&
            scope.row.calced_salary_by_month[index] &&
            toRoundFloat(
              scope.row.calced_salary_by_month[index].total_calculated
            )) ||
          0
        }}</template>
      </el-table-column>

      <el-table-column
        align="center"
        width="180"
        prop="calced_salary_summa"
        label="Itogo"
      >
        <template slot-scope="scope">
          {{ toRoundFloat(scope.row.calced_salary_summa) }}
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import monthNames from '@/constants/js/month.names.js'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { toRoundFloat } from '@/utils'

const currentYear = new Date().getFullYear()

export default {
  data() {
    return { currentYear }
  },
  computed: {
    ...mapState(['reportsStore']),
    ...mapGetters(['filteredReport25']),
    months() {
      return monthNames || []
    }
  },

  methods: { toRoundFloat },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Xodimlarga to'lovlar" }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
